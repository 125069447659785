import React, { Component } from 'react'
import Layout from '../Layout/Layout'
import Text from '../Text/Text'
import Link from 'next/link'
import ErrorButton from './ErrorButton'
import Image from './../Image/Image'
import { Router } from '../../routes'
import {
  ErrorContainer,
  ErrorInfoAndLinks,
  StyledText,
} from './ExportContainers'

class ErrorLayout extends Component {
  constructor(props) {
    super(props)
    this.handleOnButtonClick = this.handleOnButtonClick.bind(this)
  }

  handleOnButtonClick(e) {
    e.preventDefault()
    const { trackPdp404Fn, errorButtonText, href } = this.props
    if (typeof trackPdp404Fn === 'function') {
      trackPdp404Fn('Click', `CTA-Error 404-${errorButtonText}`)
    }
    Router.push(href || '/')
  }

  render() {
    const Content = props => (
      <ErrorContainer {...props}>
        <Image srcSet={props.srcSet} src={props.src} />
        <ErrorInfoAndLinks>
          <p>
            <Text size="large" weight="bold" color="#333333">
              {props.warningText}
            </Text>
          </p>
          <StyledText size="medium">{props.suggestionText}</StyledText>
          <Link
            href={props.href || '/'}
            passHref
            onClick={this.handleOnButtonClick}
            data-testid="error-page-link"
          >
            <ErrorButton
              text={props.errorButtonText}
              btnTestId={props.btnTestId}
            />
          </Link>
        </ErrorInfoAndLinks>
      </ErrorContainer>
    )

    return this.props.noLayout ? (
      <Content {...this.props} />
    ) : (
      <Layout
        noWrapper="fromMobile"
        categoryList={this.props.categoryList}
        organizationData={this.props.organizationData}
      >
        <Content {...this.props} />
      </Layout>
    )
  }
}

export default ErrorLayout
