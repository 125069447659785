import styled from 'styled-components'
import { SCREEN_SIZE } from '../../lib/Media'
import Text from '../Text/Text'

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f5f7;
  padding-bottom: 5rem;

  ${({ noLayout }) =>
    noLayout &&
    `
    position: relative;
    left: -1.5rem;
    width: calc(100% + 3rem);
  `}
`

export const ErrorInfoAndLinks = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  ${SCREEN_SIZE.Only.Mobile} {
    text-align: center;
    margin: 1rem 1rem 2rem 1rem;
    margin-top: ${props => props.marginTop || '1rem'};
  }
  > div > :first-child {
    margin-bottom: ${props => props.marginBelowHeading || '0.25rem'};
  }
`

export const LinksDiv = styled.div`
  > p:not(:last-child) {
    margin-bottom: 1rem;
  }
  ${SCREEN_SIZE.Only.Mobile} {
    text-align: center;
  }
  margin-top: ${props => props.marginTop || '1.5rem'};
`

export const StyledText = styled(Text)`
  color: #696969;
  letter-spacing: ${props => props.letterSpacing || 'normal'};
  margin-bottom: 1rem;
  display: inline-block;
`

export const LinkText = styled(Text)`
  cursor: pointer;
  color: #1557bf;
`

export const DivForButton = styled.div`
  > p:not(:last-child) {
    margin-bottom: 1rem;
  }
  ${SCREEN_SIZE.Only.Mobile} {
    text-align: center;
    margin-top: 2.5rem;
  }
  ${SCREEN_SIZE.From.Tablet} {
    margin-top: ${props => props.marginTop || '0.75rem'};
  }
`
