import React from 'react'
import styled, { keyframes } from 'styled-components'

const StyledButton = styled.button`
  background-color: #1557bf;
  border: 0;
  color: white;
  border-radius: ${props => (props.type === 'capsule' ? '2rem' : '0.75rem')};
  font-family: Lato, sans-serif;
  outline: none;
`
const ButtonContainer = styled.div`
  height: 2.8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const ButtonCss = styled.div`
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
  border: 0.2rem solid white;
  border-top-color: #1457bf;
  animation: ${spin} 1000ms infinite linear;
`

// A loader component to show loader insider a button
const ButtonLoader = props => (
  <ButtonContainer>
    <ButtonCss data-testid="loader" />
    {props.children}
  </ButtonContainer>
)

/* The Button class will provide the button for a normal flow
But, when loading flag is set true it will append loader inside
the button */
class Button extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { className, children, onClick, type, disabled, loading } = this.props
    const dataTestId = this.props['data-testid'] || 'button'
    return (
      <StyledButton
        className={className}
        onClick={onClick}
        data-testid={dataTestId}
        type={type}
        disabled={disabled}
      >
        {loading ? <ButtonLoader>{children}</ButtonLoader> : children}
      </StyledButton>
    )
  }
}

Button.defaultProps = {
  onClick: () => {},
  loading: false,
}

export default Button
