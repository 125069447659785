import React, { Component } from 'react'
import styled from 'styled-components'
import Button from '../Button/Button'
import Text from '../Text/Text'
import { SCREEN_SIZE } from '../../lib/Media'

const FullWidthButton = styled(Button)`
  cursor: pointer;
  text-align: center;
  color: #fff;
  height: 3rem;
  ${SCREEN_SIZE.Only.Mobile} {
    width: 100%;
    max-width: 20.5rem;
  }
  ${SCREEN_SIZE.From.Tablet} {
    padding: 0.35rem 1.65rem;
    width: 15.5rem;
  }
`
const TextForMobile = styled(Text)`
  ${SCREEN_SIZE.From.Tablet} {
    display: none;
  }
`

const TextFromTablet = styled(Text)`
  display: none;
  ${SCREEN_SIZE.From.Tablet} {
    display: inline-block;
  }
`

class ErrorButton extends Component {
  render() {
    const dataTestId =
      this.props['data-testid'] || this.props?.btnTestId || 'error-page-button'
    return (
      <FullWidthButton type="capsule" data-testid={dataTestId}>
        <TextForMobile
          size="large"
          weight="bold"
          data-testid={`${dataTestId}-text`}
        >
          {this.props.text}
        </TextForMobile>
        <TextFromTablet
          size="medium"
          weight="bold"
          data-testid={`${dataTestId}-text`}
        >
          {this.props.text}
        </TextFromTablet>
      </FullWidthButton>
    )
  }
}

export default ErrorButton
