import React, { Component, Fragment } from 'react'
import Head from 'next/head'
import ErrorLayout from './ErrorLayout'
import Error404Img from './../../static/404/404-Error@1x.png'
import Error404Img2X from './../../static/404/404-Error@2x.png'
import Error404Img3X from './../../static/404/404-Error@3x.png'
import { EventTrackingConsumer } from '../EventTrackingProvider'
import { GTM_ENV_VAR as GEV, PAGE_TYPES } from '../../lib/eventTrackerFunction'

class Error404 extends Component {
  constructor(props) {
    super(props)

    this.getPageContent = this.getPageContent.bind(this)
    this.trackPdp404Fn = this.trackPdp404Fn.bind(this)
  }

  getPageContent() {
    return {
      warningText: "We can't seem to find the page you're looking for",
      suggestionText: 'Try searching keywords, or go to Home.',
      errorButtonText: 'Back to Home',
    }
  }

  trackPdp404Fn(eventAcion, eventLabel) {
    this.props.track({
      [GEV.EVENT_ACTION]: eventAcion,
      [GEV.EVENT_LABEL]: eventLabel,
      [GEV.PAGE_TYPE]: PAGE_TYPES.PRODUCT,
    })
  }

  componentDidMount() {
    if (this.props.isPdp) {
      this.trackPdp404Fn('Landed', 'Error 404')
    }
  }

  render() {
    const { noLayout, categoryList, organizationData } = this.props
    const {
      warningText,
      suggestionText,
      errorButtonText,
    } = this.getPageContent()
    return (
      <Fragment>
        <Head>
          <title>Error 404 | FairPrice Singapore </title>
        </Head>
        <ErrorLayout
          data-testid="error-page-404"
          categoryList={categoryList}
          organizationData={organizationData}
          noLayout={noLayout}
          srcSet={`${Error404Img} 1x, ${Error404Img2X} 2x, ${Error404Img3X} 3x`}
          src={`${Error404Img}`}
          warningText={warningText}
          suggestionText={suggestionText}
          errorButtonText={errorButtonText}
          btnTestId="error-button-404"
          trackPdp404Fn={this.props.isPdp ? this.trackPdp404Fn : ''}
        />
      </Fragment>
    )
  }
}

const Error404Wrap = props => (
  <EventTrackingConsumer>
    {({ track }) => <Error404 {...props} track={track} />}
  </EventTrackingConsumer>
)

Error404Wrap.defaultProps = {}

export default Error404Wrap
